import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';

/**
 * Mock custom page document.
 */
export const mockChangelogDocument: ReadChangelogType['data'] = {
  author: {
    id: '225943600878213594165265',
    name: 'Cozy Bear',
  },
  created_at: new Date('9/19/2019').toISOString(),
  content: {
    body: 'Go tell it on the mountain',
  },
  links: {
    project: '/versions/1.0.0/projects/devilstore',
  },
  metadata: {
    description: 'custompage-mock-description',
    image: {
      uri: null,
      url: 'https://files.readme.io/qbtynrr3jn-owlbert.png',
    },
    keywords: 'pug, warm blanket, nap',
    title: 'test-metadata-title',
  },
  privacy: {
    view: 'public',
  },
  slug: 'test-slug',
  title: 'test-title',
  type: 'added',
  uri: '/versions/1.0.0/guides/test-slug',
  updated_at: new Date('9/19/2019').toISOString(),
};
