import type { ChangelogRouteProps } from './Changelog';
import type { CustomPageRouteProps } from './CustomPage';
import type { DocRouteProps } from './Doc';
import type { HistoryRouteProps } from './GitHistory';
import type { ReferenceRouteProps } from './Reference';
import type { HubResponseProps } from '@readme/iso';

import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { InitializeSuperHubSidebar, useSuperHubStore } from '@core/store';
import { createMockReferencePage } from '@core/store/SuperHub/Document/mock';
import { createMockSidebarCategory, createMockSidebarPage } from '@core/store/SuperHub/Sidebar/mock';
import ScrollTop from '@core/utils/ScrollTop';

import PageNotFound from '@routes/PageNotFound';

import Recipes from '../Tutorials';

import SuperHubChangelog from './Changelog';
import SuperHubCustomPage from './CustomPage';
import SuperHubDoc from './Doc';
import SuperHubEditor from './Editor';
import SuperHubGitHistory from './GitHistory';
import RedirectToProjectFirstPage from './RedirectToProjectFirstPage';
import RedirectToSidebarFirstPage from './RedirectToSidebarFirstPage';
import SuperHubReference from './Reference';
import { SuperHubRoutePaths } from './types';

const mockDocument = createMockReferencePage({ type: 'endpoint' });
const mockSidebar = [
  createMockSidebarCategory({
    pages: [
      /**
       * @todo Remove this type assertion once API supports typings for the reference section sidebar.
       */
      createMockSidebarPage({
        ...mockDocument,
        parent: mockDocument.parent?.uri || null,
        category: mockDocument.category.uri,
      }),
      createMockSidebarPage({ type: 'endpoint' }),
      createMockSidebarPage({ type: 'endpoint' }),
    ],
  }),
  createMockSidebarCategory({
    pages: [
      createMockSidebarPage({ type: 'endpoint' }),
      createMockSidebarPage({ type: 'endpoint' }),
      createMockSidebarPage({ type: 'endpoint' }),
    ],
  }),
  createMockSidebarCategory({
    pages: [createMockSidebarPage(), createMockSidebarPage(), createMockSidebarPage()],
  }),
];

type RoutePropsType = HubResponseProps<
  ChangelogRouteProps & CustomPageRouteProps & DocRouteProps & HistoryRouteProps & ReferenceRouteProps
>;

/**
 * Top-level browser router for SuperHub.
 */
export default function SuperHubRoutes(props: RoutePropsType) {
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);
  const { pathname } = useLocation();

  // TODO: Remove once API supports reference section sidebar and page document.
  const mockedProps =
    'document' in props && props.document?.slug === 'FIXME'
      ? ({
          ...props,
          document: mockDocument,
          sidebar: mockSidebar,
        } as RoutePropsType)
      : props;

  const { sidebar: initialSidebar } = mockedProps;

  return (
    <InitializeSuperHubSidebar sidebar={initialSidebar}>
      <Switch>
        {/* View page document */}
        <Route path={SuperHubRoutePaths.viewGuidePage}>
          <ScrollTop deps={[pathname]} smooth />
          <RedirectToSidebarFirstPage />
          <SuperHubDoc {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewReferencePage}>
          <ScrollTop deps={[pathname]} smooth />
          <SuperHubReference {...mockedProps} />
        </Route>
        <Route path={SuperHubRoutePaths.viewCustomPage}>
          <RedirectToProjectFirstPage />
          <SuperHubCustomPage {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewChangelog}>
          <SuperHubChangelog {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewRecipes}>
          <Recipes {...props} />
        </Route>

        {!!isSuperHubAdmin && (
          <>
            {/* Edit API definitions */}
            <Route path={SuperHubRoutePaths.editDefinition}>
              <SuperHubEditor {...props} />
            </Route>
            <Route path={SuperHubRoutePaths.viewDefinition}>
              <Redirect to="/reference" />
            </Route>

            {/* Edit page document */}
            <Route path={SuperHubRoutePaths.editPage}>
              <RedirectToSidebarFirstPage />
              <SuperHubEditor {...mockedProps} />
            </Route>

            {/* Create page document */}
            <Route
              path={[
                SuperHubRoutePaths.createGuidePage,
                SuperHubRoutePaths.createReferencePage,
                SuperHubRoutePaths.createCustomPage,
                SuperHubRoutePaths.createChangelog,
              ]}
            >
              <SuperHubEditor {...props} />
            </Route>

            {/* Compare page history commit diffs */}
            <Route path={SuperHubRoutePaths.comparePage}>
              <SuperHubGitHistory {...props} />
            </Route>
          </>
        )}

        <Route>
          <PageNotFound {...props} />
        </Route>
      </Switch>
    </InitializeSuperHubSidebar>
  );
}
