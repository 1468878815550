import React, { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import type { ConfigContextValue, ProjectContextValue, VersionContextValue } from '@core/context';
import { ConfigContext, ProjectContext, VersionContext } from '@core/context';
import useIsTrialActive from '@core/hooks/useIsTrialActive';
import usePlanPermissions from '@core/hooks/usePlanPermissions';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore, useSuperHubStore } from '@core/store';

import DashEditor from '@ui/MarkdownEditor/DashEditor';
import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext, useFormRecoveryContext, useEditorValueProxyContext } from '../../Context';

/**
 * Renders a form field that registers the @ui/MarkdownEditor component
 * with the SuperHubEditorFormContext.
 */
function MarkdownEditor() {
  const uid = useUniqueId('SuperHubEditorForm');
  const { domainFull } = useContext(ConfigContext) as ConfigContextValue;
  const { version_clean: version } = useContext(VersionContext) as VersionContextValue;
  const {
    project: { fullBaseUrl, variableDefaults, plan, planOverride, parent, subdomain },
  } = useContext(ProjectContext) as ProjectContextValue;
  const glossaryTerms = useProjectStore(s => s.data.glossary);
  const isTrialActive = useIsTrialActive();
  const hasReusableContentPermissions = usePlanPermissions(planOverride || plan, 'reusableContent');
  const isReusableContentEnabled = isTrialActive || hasReusableContentPermissions;
  const { pathname } = useLocation();
  const { editorValueProxy, setEditorValueProxy } = useEditorValueProxyContext();
  const [customBlocks, updateCustomBlock, isRawMode] = useSuperHubStore(s => [
    s.document.customBlocks,
    s.document.updateCustomBlock,
    s.editor.isRawMode,
    s.editor.updateRawMode,
  ]);

  const {
    control,
    formState: { defaultValues },
    setValue,
  } = useSuperHubEditorFormContext();
  const { isRecovered } = useFormRecoveryContext();

  /** For custom pages only, indicates whether HTML mode is enabled or not. */
  const isHtmlMode = useWatch({ control, name: 'content.type' }) === 'html';

  // When switching from MD to HTML/Raw mode, we have to update our form field's
  // value in an async way in order to preserve changes made to the editor body.
  // Otherwise, changes made while in MD mode will get lost during the mode
  // change. We must run this update "inline" as opposed to inside an effect
  // because the field must update *before* the editor component is re-rendered.
  if (isRawMode || isHtmlMode) {
    setValue('content.body', editorValueProxy?.toString() || '');
  }

  return (
    <RHFGroup control={control} id={uid('content-body')} name="content.body">
      {({ field, fieldState }) => (
        <DashEditor
          // The key is used to force the MarkdownEditor to re-render when the
          // content changes. This is necessary because the MarkdownEditor
          // doesn't update its internal state when the `field.value` changes.
          key={`${isRecovered ? 'recovered:' : ''}${pathname}:${defaultValues?.content?.body}`}
          customBlocks={customBlocks}
          // DashEditor uses `doc` prop differently depending on whether it's
          // in MD mode vs HTML/Raw mode. When in MD mode, the `doc` prop acts
          // as the initial value only when rendered for the first time,
          // triggered by a `key` change. When in HTML/Raw mode, it acts as a
          // controlled input for the HTML or raw code editor.
          //
          // Even when used as an initial value, we need to pass the current
          // field value so the editor can re-initialize and retain the most
          // recent change when switching between HTML or raw mode.
          doc={{ value: field.value || '' }}
          domainFull={domainFull}
          glossaryTerms={glossaryTerms}
          html={isHtmlMode ? field.value || '' : undefined}
          htmlMode={isHtmlMode}
          onChange={valueProxy => {
            // Process change event only when in MD mode.
            if (isRawMode || isHtmlMode) return;

            // For performance reasons we don't serialize the editor value with
            // `editor.toString()` on every change event, but we do want to
            // update the field's dirty state when the value changes. So we call
            // `field.onChange` with an empty string to trigger the dirty state.
            //
            // Delay calling `editor.toString()` as late as possible, e.g. just
            // before form submission or during an onBlur event.
            if (valueProxy.dirty && !fieldState.isDirty) field.onChange('');
          }}
          onCustomBlockSave={({ data }) => {
            updateCustomBlock(data);
          }}
          onHtmlChange={html => {
            field.onChange(html);
          }}
          onInit={setEditorValueProxy}
          onRawChange={value => {
            field.onChange(value);
          }}
          parentSubdomain={parent?.subdomain}
          projectBaseUrl={fullBaseUrl}
          rawMode={isRawMode}
          reusableContentMode={isReusableContentEnabled ? 'default' : 'no-plan-access'}
          subdomain={subdomain}
          useAPIv2
          useReusableContent={isReusableContentEnabled}
          variableDefaults={variableDefaults}
          version={version}
        />
      )}
    </RHFGroup>
  );
}

export default MarkdownEditor;
