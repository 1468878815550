import React, { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import type { UserContextValue } from '@core/context';
import { UserContext } from '@core/context';
import { useSuperHubStore, type SuperHubDocumentData } from '@core/store';
import { isChangelog } from '@core/store/SuperHub/Document/util';

import { defaultChangelog, defaultCustomPage, defaultGuidePage, defaultReferencePage } from './defaults';
import { EditorValueProxyContextProvider } from './EditorValueProxyContext';
import { FormConfirmationContextProvider } from './FormConfirmationContext';
import { FormRecoveryContextProvider } from './FormRecoveryContext';

/**
 * Hash of default documents by route section to use as the form's empty state
 * when creating new pages.
 */
const defaultDocumentBySection = {
  changelog: defaultChangelog,
  docs: defaultGuidePage,
  page: defaultCustomPage,
  reference: defaultReferencePage,
};

/**
 * Provides a shared react-hook-form context for the SuperHub editor form.
 */
export default function SuperHubEditorFormContext({ children }) {
  const [isCreateNewPage, documentData, routeSection] = useSuperHubStore(s => [
    s.editor.isCreateNewPage,
    s.document.data,
    s.routeSection,
  ]);
  const { user } = useContext(UserContext) as unknown as UserContextValue;

  const defaultValues = useMemo(() => {
    if (!routeSection) return {};

    if (isCreateNewPage) {
      const defaultDocument = defaultDocumentBySection[routeSection];
      return isChangelog(defaultDocument)
        ? {
            ...defaultDocument,
            author: {
              id: user.teammateUserId,
              name: user.name,
            },
          }
        : defaultDocument;
    }

    // Use store document as defaults when editing existing pages.
    return documentData || {};
  }, [documentData, isCreateNewPage, routeSection, user.name, user.teammateUserId]);

  const formValue = useForm<SuperHubDocumentData>({ defaultValues });

  useEffect(() => {
    formValue.reset(defaultValues);
  }, [defaultValues, formValue]);

  return (
    <FormProvider {...formValue}>
      <FormRecoveryContextProvider>
        <EditorValueProxyContextProvider>
          <FormConfirmationContextProvider>{children}</FormConfirmationContextProvider>
        </EditorValueProxyContextProvider>
      </FormRecoveryContextProvider>
    </FormProvider>
  );
}

export const useSuperHubEditorFormContext = () => useFormContext<SuperHubDocumentData>();
export * from './EditorValueProxyContext';
export * from './FormConfirmationContext';
export * from './FormRecoveryContext';
