import type { ApiDefinitionProps } from '../ApiDefinition';
import type { ChangelogRouteProps } from '../Changelog';
import type { CustomPageRouteProps } from '../CustomPage';
import type { DocRouteProps } from '../Doc';
import type { ReferenceRouteProps } from '../Reference';
import type { HubResponseProps } from '@readme/iso';

import React from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import useRoute from '@core/hooks/useRoute';
import {
  ConnectSuperHubDocumentToApi,
  ConnectSuperHubSidebarToApi,
  InitializeSuperHubDocument,
  useSuperHubStore,
} from '@core/store';
import RedirectWithHash from '@core/utils/RedirectWithHash';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';
import Recipes from '@routes/Tutorials';

import Flex from '@ui/Flex';
import Spinner from '@ui/Spinner';

import ApiDefinition from '../ApiDefinition';

import EmptyEditor from './Empty';
import SuperHubEditorForm from './Form';
import styles from './index.module.scss';
import SectionNav from './SectionNav';
import SidebarNav from './SidebarNav';

function Content({
  apiDefinitions,
  document: initialDocument,
  ...props
}: HubResponseProps<
  ApiDefinitionProps | ChangelogRouteProps | CustomPageRouteProps | DocRouteProps | ReferenceRouteProps
>) {
  const bem = useClassy(styles, 'SuperHubEditor');
  const { action, slug, section = '' } = useParams<SuperHubRouteParams>();
  const isDocumentLoading = useSuperHubStore(s => s.document.isLoading && s.document.data !== initialDocument);

  /**
   * Indicates we are rendering the editor in an empty state where there are no
   * categories or pages yet created. Recipes handles its own empty state, so we
   * continue rendering and let the recipes component do that.
   *
   * @todo Figure out how to render empty states for api definitions. When
   * there, we have to check if any definitions have been created or if any
   * pages exist in the sidebar. If both are true, then redirect to the full
   * EmptyEditor state to then render the "setup" flow.
   */
  const isEmptyState = action === 'update' && !slug && !['api-definition', 'recipes'].includes(section);

  return (
    <Flex align="stretch" className={bem('&', 'rm-Guides')} gap={0} layout="col">
      <SectionNav className={bem('-nav')} />
      {isEmptyState ? (
        <EmptyEditor />
      ) : (
        <div className={bem('-container', 'rm-Container rm-Container_flex')}>
          {section === 'recipes' ? (
            <Recipes {...props} />
          ) : (
            <>
              <SidebarNav className={bem('-sidebar')} />
              {isDocumentLoading ? (
                <div className={bem('-spinner')}>
                  <Spinner size="xxl" />
                </div>
              ) : (
                <div className={bem('-content')}>
                  {section === 'api-definition' ? (
                    <ApiDefinition apiDefinitions={apiDefinitions} />
                  ) : (
                    <SuperHubEditorForm />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Flex>
  );
}

/**
 * Renders a page document MD editor that allows users to live-update their doc
 * and persist those changes.
 */
export default function SuperHubEditor(
  props: HubResponseProps<
    ApiDefinitionProps | ChangelogRouteProps | CustomPageRouteProps | DocRouteProps | ReferenceRouteProps
  >,
) {
  const { document: initialDocument = null } = props;
  const initialCustomBlocks = 'customBlocks' in props ? props.customBlocks : undefined;

  const { slug } = useParams<SuperHubRouteParams>();

  // When route data returns with a redirect, then follow the redirect path.
  const { redirected, redirectedPath } = useRoute(props);
  if (redirected && redirectedPath) {
    return <RedirectWithHash to={redirectedPath} />;
  }

  return (
    <ConnectSuperHubSidebarToApi>
      <InitializeSuperHubDocument customBlocks={initialCustomBlocks} document={initialDocument}>
        <ConnectSuperHubDocumentToApi
          // When we're ready to turn on live syncing, flip this flag to true.
          revalidateOnFocus={false}
          slug={slug}
        >
          <Content {...props} />
        </ConnectSuperHubDocumentToApi>
      </InitializeSuperHubDocument>
    </ConnectSuperHubSidebarToApi>
  );
}
