import React from 'react';

import { useSuperHubStore } from '@core/store';

import { CollapsedStateProvider } from '../CollapsedState';
import { ConfirmDeletionProvider } from '../ConfirmDeletion';

import { ApiReferencePages } from './ApiReferencePages';
import { SidebarNavCategories } from './Categories';
import { SidebarNavRoot } from './Root';

export interface SidebarNavTreeProps {
  className?: string;
}

export const SidebarNavTree = React.memo(function SidebarNavTree({ className }: SidebarNavTreeProps) {
  const [isReferenceRoute, categories] = useSuperHubStore(s => [s.isReferenceRoute, s.sidebar.data]);

  return (
    <SidebarNavRoot className={className}>
      {!!isReferenceRoute && <ApiReferencePages />}
      <ConfirmDeletionProvider>
        <CollapsedStateProvider>
          <SidebarNavCategories categories={categories} />
        </CollapsedStateProvider>
      </ConfirmDeletionProvider>
    </SidebarNavRoot>
  );
});
