import React, { useEffect, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import AsidePanel from '@routes/SuperHub/Layout/AsidePanel';

import Button from '@ui/Button';
import FileUploader from '@ui/FileUploader';
import Flex from '@ui/Flex';
import Graphic from '@ui/Graphic';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Radio from '@ui/Radio';
import RadioGroup from '@ui/RadioGroup';
import { RHFGroup } from '@ui/RHF';

import { useApiReferenceCreatorForm } from './Context';
import styles from './index.module.scss';
import owlbertImg from './owlbert-juggles.webp';

/**
 * List of different forms that you can generate an API reference from.
 */
type FormType = 'manual' | 'openapi' | 'postman' | 'swagger';

/**
 * Hash object containing the nav title to show based on form type.
 */
const navTitlesByFormType: Record<FormType, React.ReactNode> = {
  manual: 'Start from Scratch',
  openapi: (
    <>
      <Graphic name="openapi" size="md" />
      OpenAPI
    </>
  ),
  postman: (
    <>
      <Graphic name="postman" size="md" />
      Postman
    </>
  ),
  swagger: (
    <>
      <Graphic name="swagger" size="md" />
      Swagger
    </>
  ),
};

export default function ApiReferenceCreatorForm() {
  const bem = useClassy(styles, 'ApiReferenceCreatorForm');
  const uid = useUniqueId('ApiReferenceCreator');

  const [formType, setFormType] = useState<FormType>();
  const [uploadFileName, setUploadFileName] = useState<string>();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useApiReferenceCreatorForm();

  useEffect(() => {
    // Reset form whenever we change form types.
    reset();
  }, [formType, reset]);

  const onSubmit = handleSubmit(async data => {
    // eslint-disable-next-line no-console
    console.log('TODO: handleSubmit', data);
  });

  return (
    <AsidePanel className={bem('&')} label="API Definition creation form">
      <div className={bem('-layout')}>
        {!!formType && (
          <nav className={bem('-nav')}>
            <Button circular ghost kind="minimum" onClick={() => setFormType(undefined)} size="sm">
              <Icon name="arrow-left" />
            </Button>
            <Flex align="center" gap="xs" justify="center">
              {navTitlesByFormType[formType]}
            </Flex>
          </nav>
        )}

        <div className={bem('-panel', formType && '-panel_next')}>
          <Flex align="center" className={bem('-home')} gap={0} justify="center" layout="col" tag="section">
            <img alt="owlbert juggles" className={bem('-home-graphic')} height="100" src={owlbertImg} width="118" />
            <h4 className={bem('-home-heading')}>Add API Reference</h4>
            <p className={bem('-home-description')}>
              ReadMe can generate your API reference from your specification file.
            </p>
            <Flex className={bem('-home-button-group')} gap={0} layout="col">
              <Button fullWidth kind="secondary" onClick={() => setFormType('openapi')} size="sm">
                <Graphic name="openapi" size="md" />
                OpenAPI Spec
                <Icon name="arrow-right" />
              </Button>
              <Button fullWidth kind="secondary" onClick={() => setFormType('swagger')} size="sm">
                <Graphic name="swagger" size="md" />
                Swagger Spec
                <Icon name="arrow-right" />
              </Button>
              <Button fullWidth kind="secondary" onClick={() => setFormType('postman')} size="sm">
                <Graphic name="postman" size="md" />
                Postman Collection
                <Icon name="arrow-right" />
              </Button>
              <Button fullWidth kind="secondary" onClick={() => setFormType('manual')} size="sm">
                <Graphic name="postman" size="md" style={{ visibility: 'hidden' }} />
                Start from Scratch
                <Icon name="arrow-right" />
              </Button>
            </Flex>
          </Flex>

          <form className={bem('-form')} onSubmit={onSubmit}>
            <Flex align="stretch" className={bem('-form-fields')} gap={0} justify="start" layout="col">
              {formType === 'manual' ? (
                <>
                  <RHFGroup control={control} id={uid('api-title')} label="API Title" name="api.title" required>
                    {({ field }) => <Input {...field} size="sm" />}
                  </RHFGroup>
                  <RHFGroup
                    control={control}
                    description={
                      <span>
                        Indicate variables within curly brackets <code className={bem('-code')}>&#123; &#125;</code>
                      </span>
                    }
                    id={uid('target-host-url')}
                    isUrl
                    label="Target Host URL"
                    name="api.serverUrl"
                    required
                  >
                    {({ field }) => <Input {...field} size="sm" />}
                  </RHFGroup>
                  <RHFGroup control={control} label="Authentication Type" name="api.authType">
                    {({ field }) => (
                      <RadioGroup fullWidth>
                        <Radio {...field} checked={field.value === 'none'} label="None" value="none" />
                        <Radio {...field} checked={field.value === 'api-keys'} label="API Keys" value="api-keys" />
                        <Radio {...field} checked={field.value === 'basic'} label="Basic" value="basic" />
                        <Radio {...field} checked={field.value === 'bearer'} label="Bearer" value="bearer" />
                        <Radio {...field} checked={field.value === 'oauth-2.0'} label="OAuth 2.0" value="oauth-2.0" />
                      </RadioGroup>
                    )}
                  </RHFGroup>
                </>
              ) : (
                <>
                  <RHFGroup control={control} id={uid('import-url')} isUrl label="Import from URL" name="api.importUrl">
                    {({ field }) => <Input placeholder="https://example.com/collection.json" {...field} size="sm" />}
                  </RHFGroup>
                  <hr className={bem('-form-divider')} />
                  <RHFGroup control={control} id={uid('import-file')} label="Import from Files" name="api.importFile">
                    {({ field }) => (
                      <Flex align="center" className={bem('-import-file')} gap="sm" justify="start" layout="col">
                        <FileUploader
                          accept="application/json,.yaml"
                          fullWidth
                          icon={uploadFileName ? null : undefined}
                          kind="secondary"
                          onChange={data => {
                            if (!data) {
                              setUploadFileName(undefined);
                            }
                            field.onChange(data ?? null);
                          }}
                          onUpload={async file => {
                            // TODO: Replace with real request to API upload.
                            await new Promise(resolve => setTimeout(resolve, 1000));
                            setUploadFileName(file.name);
                            return { openapi: '3.0.2' };
                          }}
                          preview
                          size="sm"
                        >
                          {uploadFileName ?? undefined}
                        </FileUploader>
                      </Flex>
                    )}
                  </RHFGroup>
                </>
              )}
            </Flex>
            <footer className={bem('-form-footer')}>
              <Button circular disabled={!isDirty} fullWidth type="submit">
                Create API Reference
              </Button>
            </footer>
          </form>
        </div>
      </div>
    </AsidePanel>
  );
}

export * from './Context';
