import React, { useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';
import { isChangelog, isCustomPage, isGuidesPage, isReferencePage } from '@core/store/SuperHub/Document/util';

import PublishMenu from '@routes/Dash/Project/DocsEditor/PublishMenu';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import type Modal from '@ui/Modal';
import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext } from '../Context';

import CustomPageMenu from './CustomPageMenu';
import styles from './index.module.scss';
import MetadataModal from './MetadataModal';
import PageMenu from './PageMenu';
import TypeSelector from './TypeSelector';

interface LayoutProps {
  children?: React.ReactNode;
}
/**
 * Renders the main `<form>` along with its action controls, submit handlers, and common form fields for the editor.
 * Accepts children to render form fields unique to the form's current page type.
 */
function Layout({ children }: LayoutProps) {
  const bem = useClassy(styles, 'SuperHubEditorFormLayout');
  const uid = useUniqueId('SuperHubEditorFormLayout');
  const metadataModalRef = useRef<Modal>(null);

  const {
    control,
    formState: { isDirty, isSubmitting, defaultValues },
  } = useSuperHubEditorFormContext();

  return (
    <Flex align="stretch" className={bem()} gap={0} layout="col">
      <div className={bem('-content')}>
        <Flex align="center" className={bem('-controls')} gap="sm" justify="start">
          {(isGuidesPage(defaultValues) || isReferencePage(defaultValues) || isChangelog(defaultValues)) && (
            <RHFGroup control={control} id={uid('type')} name="type">
              {({ field }) => <TypeSelector {...field} />}
            </RHFGroup>
          )}

          <RHFGroup control={control} id={uid('privacy-view')} name="privacy.view">
            {({ field }) => (
              <PublishMenu
                isHidden={field.value !== 'public'}
                updateFormData={(_, isHidden) => {
                  field.onChange(isHidden ? 'anyone_with_link' : 'public');
                }}
              />
            )}
          </RHFGroup>

          {isCustomPage(defaultValues) && <CustomPageMenu />}

          <PageMenu onOpenMetadataModal={() => metadataModalRef.current?.toggle(true)} />
        </Flex>

        {children}

        <MetadataModal ref={metadataModalRef} />
      </div>
      <div className={bem('-actions')}>
        <Button disabled={!isDirty} loading={isSubmitting} type="submit">
          Save
        </Button>
      </div>
    </Flex>
  );
}

export { default as LayoutBody } from './Body';
export { default as LayoutHeader } from './Header';
export { default as LayoutTitle } from './Title';
export default Layout;
