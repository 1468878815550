import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type { ForwardedRef } from 'react';

import React, { forwardRef, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore, type SuperHubGuideReferencePage } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';

import styles from './index.module.scss';

const options: Record<
  NonNullable<TypeSelectorProps['value']>,
  {
    icon?: string;
    section: NonNullable<SuperHubRouteParams['section']> | NonNullable<SuperHubRouteParams['section']>[];
    title: string;
  }
> = {
  // Guide + reference page types
  basic: { section: ['docs', 'reference'], title: 'Page', icon: 'file' },
  link: { section: ['docs', 'reference'], title: 'External Link', icon: 'arrow-up-right' },

  // Reference page types
  endpoint: { section: 'reference', title: 'API endpoint', icon: 'share-2' },
  // webhook: { section: 'reference', title: 'Webhook', icon: 'apilogs' },

  // Changelog types
  added: { section: 'changelog', title: 'Added', icon: 'plus' },
  fixed: { section: 'changelog', title: 'Fixed', icon: 'check' },
  improved: { section: 'changelog', title: 'Improved', icon: 'heart' },
  deprecated: { section: 'changelog', title: 'Deprecated', icon: 'alert-triangle' },
  removed: { section: 'changelog', title: 'Removed', icon: 'x' },
  none: { section: 'changelog', title: 'No type' },
};

interface TypeSelectorProps {
  onChange: (value: SuperHubGuideReferencePage['type']) => void;
  value: ReadChangelogType['data']['type'] | SuperHubGuideReferencePage['type'];
}

function TypeSelector({ value = 'basic', onChange }: TypeSelectorProps, ref: ForwardedRef<HTMLButtonElement>) {
  const bem = useClassy(styles, 'TypeSelector');
  const routeSection = useSuperHubStore(s => s.routeSection);

  const filteredOptions = useMemo<Partial<typeof options>>(() => {
    return Object.fromEntries(
      Object.entries(options).filter(([, optionValue]) => {
        if (!routeSection) return false;
        return optionValue.section.includes(routeSection);
      }),
    );
  }, [routeSection]);

  const selectedOption = filteredOptions[value];

  return (
    <Dropdown className={bem('&', `_section-${routeSection}`)} clickInToClose justify="start" sticky trigger="click">
      <Button ref={ref} dropdown kind="secondary" outline size="sm">
        {!!selectedOption?.icon && (
          <Icon
            className={bem('-icon', `-icon_${value}`)}
            name={selectedOption.icon}
            wrapperClassName={bem('-icon-wrapper')}
          />
        )}
        {selectedOption?.title}
      </Button>
      <Menu>
        {Object.entries(filteredOptions).map(([type, { icon, title }]) => (
          <MenuItem
            key={type}
            active={type === value}
            onClick={() => onChange(type as SuperHubGuideReferencePage['type'])}
            role="menuitem"
          >
            {!!icon && (
              <Icon className={bem('-icon', `-icon_${type}`)} name={icon} wrapperClassName={bem('-icon-wrapper')} />
            )}
            {title}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}

export default forwardRef(TypeSelector);
