import React, { useMemo } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import '@ui/API/Group.scss';
import APISectionHeader from '@ui/API/SectionHeader';
import CopyToClipboard from '@ui/CopyToClipboard';
import Flex from '@ui/Flex';
import Spinner from '@ui/Spinner';
import Toggle from '@ui/Toggle';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

interface APIShareLog {
  enabled: boolean;
  id?: string;
  loading: boolean;
  onRegenerate: () => void;
  onToggle: () => void;
}

const APIShareLog = ({ id, enabled, loading, onRegenerate, onToggle }: APIShareLog) => {
  const { isServer } = useEnvInfo();

  const shareUrl = useMemo(() => {
    if (isServer || typeof id === 'undefined') {
      return null;
    }

    const url = new URL(window.location.href);
    url.searchParams.set('shareId', id);
    return url.toString();
  }, [isServer, id]);

  return (
    <div className={classes.APIShareLog}>
      <Flex align="center" gap="0" justify="start">
        <i aria-label="Share" className="icon-upload" />
        <APISectionHeader className={classes['APIShareLog-header-heading']} heading="Share this request" />
        <Tooltip
          content="Anyone with this URL can see the request details. All sensitive data will be hidden."
          placement="bottom"
        >
          <i aria-label="Help" className={`${classes['APIShareLog-helpTip']} icon-help-circle`} />
        </Tooltip>
        <Toggle
          checked={enabled}
          className={classes['APIShareLog-toggle']}
          disabled={loading}
          name="isSharingEnabled"
          onChange={onToggle}
          type="toggle"
        />
      </Flex>

      {!!enabled && shareUrl != null && (
        <>
          <div className={`${classes['APIShareLog-controls']} InputGroup`}>
            <>
              <div className={`InputGroup-input ${classes['APIShareLog-url']}`}>
                <span>{shareUrl}</span>
              </div>

              <CopyToClipboard className={`${classes['APIShareLog-copyBtn']} `} text={shareUrl} />
              <Tooltip content="Regenerate URL" placement="bottom">
                <button
                  aria-label="Regenerate URL"
                  className="InputGroup-button"
                  disabled={loading}
                  onClick={onRegenerate}
                >
                  {loading ? (
                    <Spinner size="sm" />
                  ) : (
                    <i className={`${classes['APIShareLog-regenerateBtn']} icon-rotate-cw`} />
                  )}
                </button>
              </Tooltip>
            </>
          </div>
          <small className={classes['APIShareLog-expire']}>Link expires after 30 days</small>
        </>
      )}
    </div>
  );
};

export default APIShareLog;
