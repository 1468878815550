import type { SuperHubDocumentData } from '..';
import type { ReadRepresentationType } from '@readme/api/src/mappings/category/types';
import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type { CustomPageReadType } from '@readme/api/src/mappings/custompage/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';
import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';

import produce from 'immer';

import { isCustomPage, isReferencePage } from '../util';

import { mockChangelogDocument } from './changelog';
import { mockCustomPageDocument } from './customPage';
import { mockGuidesPageDocument } from './guidesPage';
import { mockReferencePageDocument } from './referencePage';

let documentCount = 0;
/**
 * Helper function to make a mock document with a singular return type.
 */
function createMockDocument<Document extends SuperHubDocumentData>(document: Document, keys: Partial<Document> = {}) {
  documentCount += 1;
  const slug = keys.slug ?? `slug-${documentCount}`;
  const type = isCustomPage(document) ? 'page' : isReferencePage(document) ? 'reference' : 'guides';

  return produce(document, draft => ({
    ...draft,
    slug,
    title: `Page ${documentCount}`,
    uri: `/versions/1.0.0/${type}/${slug}`,
    ...keys,
  }));
}

/**
 * Returns a mock guides page document for testing purposes.
 */
export function createMockGuidesPage(keys?: Partial<ReadGuideType['data']>) {
  return createMockDocument(mockGuidesPageDocument, keys);
}

/**
 * Returns a mock reference page document for testing purposes.
 */
export function createMockReferencePage(keys?: Partial<ReadReferenceType['data']>) {
  return createMockDocument(mockReferencePageDocument, keys);
}

/**
 * Returns a mock custom page document for testing purposes.
 */
export function createMockCustomPage(keys?: Partial<CustomPageReadType['data']>) {
  return createMockDocument(mockCustomPageDocument, keys);
}

/**
 * Returns a mock changelog document for testing purposes.
 */
export function createMockChangelog(keys?: Partial<ReadChangelogType['data']>) {
  return createMockDocument(mockChangelogDocument, keys);
}

let categoryCount = 0;
/**
 * Returns a mock category for testing purposes.
 */
export function createMockCategory(keys: Partial<ReadRepresentationType['data']> = {}): ReadRepresentationType['data'] {
  categoryCount += 1;
  const title = keys.title ?? `Category ${categoryCount}`;
  return {
    links: { project: '' },
    title,
    uri: `/versions/1.0.0/categories/guides/${title}`,
    ...keys,
  };
}

export * from './changelog';
export * from './customPage';
export * from './guidesPage';
export * from './referencePage';
export * from './customBlocks';
