import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Spinner from '@ui/Spinner';

import DisabledStateContextProvider from './DisabledStateContext';
import styles from './index.module.scss';
import { SidebarNavTree } from './Tree';

export interface SidebarNavProps {
  className?: string;
}

export default React.memo(function SidebarNav({ className }: SidebarNavProps) {
  const bem = useClassy(styles, 'SidebarNav');
  const isLoading = useSuperHubStore(s => s.sidebar.isLoading && !s.sidebar.data.length);

  return (
    <DisabledStateContextProvider>
      {isLoading ? (
        <div className={bem('&', className, '_loading')}>
          <Spinner />
        </div>
      ) : (
        <SidebarNavTree className={bem('&', className)} />
      )}
    </DisabledStateContextProvider>
  );
});
