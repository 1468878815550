import React, { useState, useEffect, useCallback, useContext } from 'react';

import { APIBaseUrlContext } from '@core/context';
import useReadmeApi from '@core/hooks/deprecated/useReadmeApi';
import useDebounced from '@core/hooks/useDebounced';
import { useReferenceStore } from '@core/store';

import APIShareLog from '@ui/API/ShareLog';

interface ShareLogContainerProps {
  /** The selected har */
  createdAt: string;
  id: string;
  isEnabled?: boolean;
  shareId?: string;
}

const ShareLogContainer: React.FC<ShareLogContainerProps> = props => {
  const { id, createdAt } = props;
  const [isEnabled, setIsEnabled] = useState(Boolean(props.isEnabled));
  const [isLoading, setIsLoading] = useState(false);
  const [shareId, setShareId] = useState(props.shareId);
  const groupId = useReferenceStore(s => s.auth.group);

  useEffect(() => {
    setIsEnabled(Boolean(props.isEnabled));
  }, [id, props.isEnabled]);

  useEffect(() => {
    setShareId(props.shareId);
  }, [id, props.shareId]);

  const apiBaseUrl = useContext(APIBaseUrlContext);
  const {
    initRequest: initCreateShare,
    response: createShareResponse,
    isLoading: isCreateShareLoading,
  } = useReadmeApi(apiBaseUrl);
  const {
    initRequest: initToggleShare,
    response: toggleShareResponse,
    isLoading: isToggleShareLoading,
  } = useReadmeApi(apiBaseUrl);
  const {
    initRequest: initRegenerateShare,
    response: regenerateShareResponse,
    isLoading: isRegenerateResponseLoading,
  } = useReadmeApi(apiBaseUrl);

  const areAnyLoading = isCreateShareLoading || isToggleShareLoading || isRegenerateResponseLoading;
  const memoizedSetIsLoading = useCallback(() => setIsLoading(areAnyLoading), [areAnyLoading]);
  // Only bother updating the state to loading if 200ms has passed to avoid UI flickering
  const debouncedSetIsLoading = useDebounced(memoizedSetIsLoading, 200);

  useEffect(() => {
    if (areAnyLoading) {
      debouncedSetIsLoading();
      return;
    }

    // Cancel updating the state to loading if everything is done
    debouncedSetIsLoading.cancel();
    setIsLoading(false);
  }, [areAnyLoading, debouncedSetIsLoading]);

  useEffect(() => {
    if (createShareResponse == null) return;

    const { isEnabled: isEnabledRes, shareId: shareIdRes } = createShareResponse;
    setShareId(shareIdRes);
    setIsEnabled(isEnabledRes);
  }, [createShareResponse]);

  useEffect(() => {
    if (toggleShareResponse == null) return;

    const { isEnabled: isEnabledRes } = toggleShareResponse;
    setIsEnabled(isEnabledRes);
  }, [toggleShareResponse]);

  useEffect(() => {
    if (regenerateShareResponse == null) return;

    const { shareId: shareIdRes } = regenerateShareResponse;
    setShareId(shareIdRes);
  }, [regenerateShareResponse]);

  const handleToggle = useCallback(() => {
    const nextIsEnabled = !isEnabled;

    if (shareId == null) {
      initCreateShare({
        path: `api/metricshare?groupId=${groupId}`,
        method: 'post',
        body: JSON.stringify({ id, createdAt }),
      });
      return;
    }

    initToggleShare({
      path: `api/metricshare/${shareId}/toggle`,
      method: 'post',
      body: JSON.stringify({ isEnabled: nextIsEnabled }),
    });
  }, [createdAt, groupId, id, initCreateShare, initToggleShare, isEnabled, shareId]);

  const handleRegenerate = useCallback(() => {
    if (shareId == null) return;
    // Make a request to the backend to get a new share id
    initRegenerateShare({
      path: `api/metricshare/${shareId}/regenerate`,
      method: 'post',
    });
  }, [initRegenerateShare, shareId]);

  return (
    <APIShareLog
      enabled={isEnabled}
      id={shareId}
      loading={isLoading}
      onRegenerate={handleRegenerate}
      onToggle={handleToggle}
    />
  );
};

export default ShareLogContainer;
