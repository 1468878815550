import type { SuperHubRouteParams } from './types';

import React from 'react';
import { useParams } from 'react-router-dom';

import { superHubSidebarTypes, useSuperHubStore } from '@core/store';
import RedirectWithHash from '@core/utils/RedirectWithHash';

/**
 * Determines if a document is currently loaded. If not, attempts to redirect
 * the user to the first page or post in the sidebar based on the current
 * section we're on. If no first page is available, cancel the redirect and stay
 * where we are.
 */
export default function RedirectToSidebarFirstPage() {
  const { action, section, slug } = useParams<SuperHubRouteParams>();
  const [findSidebarFirstPage, isLoading, sidebarKey, isEditing] = useSuperHubStore(s => [
    s.sidebar.findSidebarFirstPage,
    s.sidebar.isLoading,
    s.sidebar.swrKey,
    s.isEditing,
  ]);

  if (!slug && section) {
    /**
     * Indicates whether the sidebar for the current section has been loaded.
     * Only then, can we proceed to find the first page to redirect to.
     */
    const isSidebarLoaded = !!sidebarKey?.[0].endsWith(superHubSidebarTypes[section]) && !isLoading;
    if (isSidebarLoaded) {
      const firstPage = findSidebarFirstPage({ includeHidden: true, includeLinks: isEditing });

      if (firstPage) {
        const redirectPath = [action, section, firstPage.slug].filter(Boolean).join('/');
        return <RedirectWithHash to={`/${redirectPath}`} />;
      }
    }
  }

  return null;
}
