import React from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

/**
 * @todo Update this with types from our API endpoint.
 */
type ApiDefinitionData = typeof defaultValues;

/**
 * @todo Update these with real defaults from an API spec when available.
 */
const defaultValues = {
  api: {
    authType: 'none',
    importFile: null,
    importUrl: null,
    title: '',
    serverUrl: '',
  },
};

/**
 * Renders the React Hook Form context provider for our API definitions form and
 * makes that form accessible to all descendant components.
 */
export function ApiReferenceCreatorFormProvider({ children }) {
  const formValues = useForm<ApiDefinitionData>({
    defaultValues,
  });

  return <FormProvider {...formValues}>{children}</FormProvider>;
}

export const useApiReferenceCreatorForm = () => useFormContext<ApiDefinitionData>();
