import React from 'react';

import { useSuperHubStore } from '@core/store';

import EmptyState from '@routes/SuperHub/Layout/EmptyState';

/**
 * Renders the empty state for the Reference section when no API definition has
 * yet been created.
 */
export default function EmptyReference() {
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);

  return <EmptyState action={isSuperHubAdmin ? '/update/api-definition' : undefined} section="reference" />;
}
