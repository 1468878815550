import React, { createContext, useContext, useMemo, useState } from 'react';

interface FormRecoveryContextValue {
  /**
   * Whether a user has recovered form data from a previous session.
   */
  isRecovered: boolean;
  setIsRecovered: React.Dispatch<React.SetStateAction<FormRecoveryContextValue['isRecovered']>> | null;
}

const FormRecoveryContext = createContext<FormRecoveryContextValue | null>(null);

/**
 * Provides `FormRecoveryContext` and makes it available to all children.
 */
export const FormRecoveryContextProvider: React.FC = ({ children }) => {
  const [isRecovered, setIsRecovered] = useState(false);

  return (
    <FormRecoveryContext.Provider
      value={useMemo(
        () => ({
          isRecovered,
          setIsRecovered,
        }),
        [isRecovered],
      )}
    >
      {children}
    </FormRecoveryContext.Provider>
  );
};

/**
 * A flag that indicates whether a user has recovered form data from a previous session.
 * Used to re-render the MarkdownEditor component when the form data is recovered.
 */
export function useFormRecoveryContext() {
  const value = useContext(FormRecoveryContext);
  if (!value) throw new Error('FormRecoveryContext.Provider is missing');
  return value;
}
