import type { ReadAPIDefinitionCollectionType, APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React, { useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Flex from '@ui/Flex';

import ApiDefinitionForm from './Form';
import styles from './index.module.scss';
import ApiDefinitionList from './List';

export interface ApiDefinitionProps {
  apiDefinitions?: ReadAPIDefinitionCollectionType;
  document?: never;
}

/**
 * Entrypoint route component for managing API definitions.
 */
const ApiDefinition: React.FC<ApiDefinitionProps> = ({ apiDefinitions }) => {
  const bem = useClassy(styles, 'ApiDefinition');
  const [formAction, setFormAction] = useState<'create' | 'update' | null>(null);
  const [formDefinition, setFormDefinition] = useState<APIDefinitionsReadType | null>(null);
  const isSuperHubDevelopment = useSuperHubStore(s => s.isSuperHubDevelopment);

  return (
    <Flex align="stretch" className={bem()} gap="md" justify="center" layout="col">
      {formAction ? (
        <ApiDefinitionForm
          action={formAction}
          allowManualEditor={formAction === 'create' && isSuperHubDevelopment}
          definition={formDefinition}
          onCancel={() => setFormAction(null)}
        />
      ) : (
        <ApiDefinitionList
          className={bem('-list')}
          collection={apiDefinitions}
          onCreate={() => {
            setFormAction('create');
          }}
          onReplace={definition => {
            setFormAction('update');
            setFormDefinition(definition);
          }}
        />
      )}
    </Flex>
  );
};

export default ApiDefinition;
