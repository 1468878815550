import React from 'react';

import useClassy from '@core/hooks/useClassy';

import APIMethod from '@ui/API/Method';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Skeleton from '@ui/Skeleton';

import styles from './index.module.scss';

interface SidebarSkeletonProps {
  /**
   * Replaces the stand-in category name with the value passed in.
   */
  category?: string;
  className?: string;
}

/**
 * Renders a skeleton of our hub sidebar with exposed props to substitute in
 * custom values into specific sections.
 */
export default function SidebarSkeleton({ category, className }: SidebarSkeletonProps) {
  const bem = useClassy(styles, 'SidebarSkeleton');

  return (
    <Flex align="stretch" className={bem('&', className)} justify="start" layout="col" tag="ul">
      <li className={bem('-item')}>
        <Skeleton width="75%" />
      </li>
      <li className={bem('-item', '-item_category')}>
        <Icon name="chevron-right" size={12} />
        <Skeleton width="25%" />
      </li>
      <li className={bem('-item')}>
        <Skeleton width="75%" />
      </li>
      <li className={bem('-item')}>
        <Skeleton width="65%" />
      </li>
      <li className={bem('-item')}>
        <Skeleton width="75%" />
      </li>
      <Flex align="center" className={bem('-item')} tag="li">
        <span className={bem('-item-label')}>{category || 'YOUR API'}</span>
        <Icon name="plus" />
      </Flex>
      <li className={bem('-item', '-item_category')}>
        <Icon name="chevron-right" size={12} />
        <Skeleton width="25%" />
      </li>
      <Flex align="center" className={bem('-item')} tag="li">
        <Skeleton width="50%" />
        <APIMethod className={bem('-item-endpoint')} fixedWidth type="get" />
      </Flex>
      <Flex align="center" className={bem('-item')} tag="li">
        <Skeleton width="60%" />
        <APIMethod className={bem('-item-endpoint')} fixedWidth type="post" />
      </Flex>
      <Flex align="center" className={bem('-item')} tag="li">
        <Skeleton width="60%" />
        <APIMethod className={bem('-item-endpoint')} fixedWidth type="patch" />
      </Flex>
      <Flex align="center" className={bem('-item')} tag="li">
        <Skeleton width="50%" />
        <APIMethod className={bem('-item-endpoint')} fixedWidth type="delete" />
      </Flex>
      <li className={bem('-item')}>
        <Skeleton width="65%" />
      </li>
    </Flex>
  );
}
