import type { CollectionWrapperType } from '@readme/api/src/core/mapper';
import type InfiniteLoader from 'react-window-infinite-loader';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { mutate as globalMutate } from 'swr';
import { unstable_serialize as unstableSerialize } from 'swr/infinite';

import { useReadmeApiInfinite } from '@core/hooks/useReadmeApi';

import { flattenPaginatedCollection } from './utils';

interface UseInfiniteLoaderDataOptions {
  /**
   * Number of items to fetch per page
   */
  perPage?: number;
  /**
   * Number of rows remaining before triggering data loading on scroll
   */
  threshold?: number;
}

/**
 * Fetches paginated data from the ReadMe APIv2 and prepares it for use with react-window-infinite-loader
 */
function useInfiniteLoaderData<CollectionItem>(url: string, options?: UseInfiniteLoaderDataOptions) {
  const { perPage = 50, threshold = 10 } = options || {};

  const [isReady, setIsReady] = useState(false);
  const [baseUrl, queryString] = url.includes('?') ? url.split('?') : [url, ''];

  if (queryString && (queryString.includes('page') || queryString.includes('per_page'))) {
    // eslint-disable-next-line no-console
    console.warn(
      '`page` and `per_page` query parameters are managed by useInfiniteLoaderData hook. Remove them from your url parameter.',
    );
  }

  const getKey = useCallback(
    (pageIndex, previousPageData) => {
      if (previousPageData?.paging?.next === null) {
        // Reached the end of the list
        return null;
      }
      return `${baseUrl}?page=${pageIndex + 1}&per_page=${perPage}${queryString ? `&${queryString}` : ''}`;
    },
    [baseUrl, perPage, queryString],
  );

  const { data, size, setSize, isLoading, isValidating, mutate } =
    useReadmeApiInfinite<CollectionWrapperType<CollectionItem>>(getKey);

  // Set the ready state once the first page of data is loaded
  useEffect(() => {
    if (!isLoading && !isReady) {
      setIsReady(true);
    }
  }, [isLoading, isReady]);

  // API data is returned as an array of pages which must be flattened to display in a list
  const items = useMemo(() => (data ? flattenPaginatedCollection<CollectionItem>(data) : []), [data]);

  const hasNextPage = useMemo(() => {
    if (!data) return false;
    const lastPage = data[data.length - 1];
    return lastPage.paging.next !== null;
  }, [data]);

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length,
    [hasNextPage, items.length],
  );

  const loadMoreItems = isValidating ? () => {} : () => setSize(size + 1);
  // If there are more items to load, add one to the item count to hold a loading indicator
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  const infiniteLoaderProps: Omit<InfiniteLoader['props'], 'children'> = {
    itemCount,
    isItemLoaded,
    loadMoreItems,
    threshold,
  };

  const reset = useCallback(() => {
    // Using the bound mutate function does not seem to work here,
    // so using the global mutate function instead
    globalMutate(unstableSerialize(getKey), undefined, { revalidate: false });
  }, [getKey]);

  return { data, items, isLoading, isReady, infiniteLoaderProps, mutate, reset };
}

export default useInfiniteLoaderData;
