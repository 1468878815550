import type { SuperHubDocumentData } from '@core/store';
import { isReferencePage } from '@core/store/SuperHub/Document/util';

export function normalizeGitDocToMongo(data?: SuperHubDocumentData) {
  if (isReferencePage(data)) {
    return {
      api: {
        method: data.api.method,
        url: '',
        params: [],
      },
      deprecated: data.state === 'deprecated',
      excerpt: data.content.excerpt ?? undefined,
      slug: data.slug,
      swagger: {
        path: data.api.path,
      },
      title: data.title,
      type: data.type,
    };
  }
  return undefined;
}
