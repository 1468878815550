import type { ReadAPIDefinitionCollectionType, APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import produce from 'immer';
import React, { useCallback, useEffect, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useReadmeApi, { fetcher } from '@core/hooks/useReadmeApi';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import ApiDefinitionForm from '../Form';

import styles from './index.module.scss';
import ApiDefinitionItem from './Item';

interface ApiDefinitionListProps {
  className?: string;
  /**
   * Represents the initial collection to use while initializing connection to
   * our API endpoint to then continually hydrate data.
   */
  collection?: ReadAPIDefinitionCollectionType;

  /**
   * Invoked when users click to "add" a new definition.
   */
  onCreate?: () => void;

  /**
   * Invoked when users click to "replace" an existing definition.
   */
  onReplace?: (definition: APIDefinitionsReadType) => void;
}

const emptyCollection = {
  data: [],
  total: 0,
};

/**
 * Renders a list of API definitions that have been previously added. Allows
 * users to manage existing API definitions to update or delete them.
 */
const ApiDefinitionList: React.FC<ApiDefinitionListProps> = ({
  className,
  collection: initialCollection,
  onCreate,
  onReplace,
}) => {
  const bem = useClassy(styles, 'ApiDefinitionList');
  const apiBaseUrl = useSuperHubStore(s => s.apiBaseUrl);

  /**
   * Contains previously fetched collection data so we can overlay our loading
   * state on top of existing data instead of a blank screen.
   * @todo Replace this with SWR option "keepPreviousData" when we upgrade SWR
   * to a newer version that includes this feature.
   * @link https://swr.vercel.app/docs/advanced/understanding#return-previous-data-for-better-ux
   */
  const previousCollection = useRef<ReadAPIDefinitionCollectionType>(initialCollection || emptyCollection);

  const {
    data: collection = previousCollection.current,
    isLoading: isCollectionLoading,
    mutate,
  } = useReadmeApi<ReadAPIDefinitionCollectionType>('/apis', {
    apiBaseUrl,
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });
  const isLoading = isCollectionLoading && collection !== initialCollection;

  useEffect(() => {
    previousCollection.current = collection;
  }, [collection]);

  const handleDelete = useCallback(
    async (definition: ReadAPIDefinitionCollectionType['data'][0]) => {
      const next = produce(collection, draft => {
        const index = draft.data.findIndex(d => definition.uri === d.uri);
        if (index >= 0) {
          draft.data.splice(index, 1);
        }
      });

      await mutate(
        async () => {
          await fetcher(`${apiBaseUrl}/apis/${definition.filename}`, {
            method: 'DELETE',
          });
          return next;
        },
        {
          optimisticData: next,
          revalidate: false,
        },
      );
    },
    [apiBaseUrl, collection, mutate],
  );

  if (!isLoading && !collection.total) {
    // TODO: Empty state. Is it correct to show the same "setup" form to prompt
    // users to create a definition?
    return <ApiDefinitionForm action="setup" />;
  }

  return (
    <Flex align="stretch" className={bem('&', className)} gap={30} justify="center" layout="col">
      <Flex align="center" tag="header">
        <h3 className={bem('-title')}>Your API Definitions</h3>
        <Button onClick={() => onCreate?.()} size="sm">
          <Icon name="plus" />
          Add Definition
        </Button>
      </Flex>

      <Flex align="stretch" className={bem('-list')} gap="sm" layout="col" tag="ul">
        {collection.data.map(definition => (
          <ApiDefinitionItem
            key={definition.uri}
            definition={definition}
            onDelete={() => handleDelete(definition)}
            onReplace={onReplace}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default ApiDefinitionList;
