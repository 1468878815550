import type { ReadChangelogCollectionType, ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type { HubResponseProps } from '@readme/iso';

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import useRoute from '@core/hooks/useRoute';
import RedirectWithHash from '@core/utils/RedirectWithHash';
import ScrollTop from '@core/utils/ScrollTop';

import ChangelogList from './List';
import ChangelogPost from './Post';
import classes from './style.module.scss';
import './style.scss';

/**
 * Shape of route specific data for changelogs.
 */
export interface ChangelogRouteProps {
  apiDefinitions?: never;
  collection?: ReadChangelogCollectionType;
  document?: ReadChangelogType['data'];
}

export type TypeIconClasses = typeof typeIconClasses;

const typeIconClasses = {
  added: `icon-plus1 ${classes.ChangelogIcon_added}`,
  fixed: `icon-check1 ${classes.ChangelogIcon_fixed}`,
  improved: `icon-heart1 ${classes.ChangelogIcon_improved}`,
  deprecated: `icon-alert-triangle ${classes.ChangelogIcon_deprecated}`,
  removed: `icon-x ${classes.ChangelogIcon_removed}`,
} as const;

export default function Changelog(props: HubResponseProps<ChangelogRouteProps>) {
  // When route data returns with a redirect, then follow the redirect path.
  const { redirected, redirectedPath } = useRoute(props);
  if (redirected && redirectedPath) {
    return <RedirectWithHash to={redirectedPath} />;
  }

  return (
    <Switch>
      <Route path="/changelog/:slug">
        <ScrollTop />
        <ChangelogPost initialChangelog={props.document} typeIconClasses={typeIconClasses} />
      </Route>
      <Route exact path="/changelog">
        <ScrollTop smooth />
        <ChangelogList initialCollection={props.collection} typeIconClasses={typeIconClasses} />
      </Route>
    </Switch>
  );
}
