import type { APIDefinitionUploadType, StagedAPIDefinitionType } from '@readme/api/src/mappings/apis/types';

import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import FileUploader from '@ui/FileUploader';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import styles from './index.module.scss';

interface ApiDefinitionImporterFields {
  file?: File;
  url?: string;
}

interface ApiDefinitionImporterProps {
  className?: string;

  /**
   * Invoked whenever an import was successfully completed.
   */
  onImport?: () => void;

  /**
   * Prefills the URL import field with this value. Useful when editing an
   * existing API definition that was previously imported by URL.
   */
  url?: string;
}

/**
 * Renders an upload form to let users import an API Definition schema either by
 * file or an external URL. When form is submitted, request is sent to API v2 to
 * process the file or URL being imported.
 */
export default function ApiDefinitionImporter({ className, onImport, url }: ApiDefinitionImporterProps) {
  const bem = useClassy(styles, 'ApiDefinitionImporter');
  const formRef = useRef<HTMLFormElement>(null);
  const apiBaseUrl = useSuperHubStore(s => s.apiBaseUrl);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setError,
  } = useForm<ApiDefinitionImporterFields>({
    defaultValues: { url },
  });

  const onSubmit = handleSubmit(async data => {
    // Prepare data to be sent to our API endpoint.
    let payload: APIDefinitionUploadType;

    if (data.file) {
      payload = { schema: data.file };
    } else {
      if (!data.url) {
        setError('url', {
          type: 'required',
          message: 'Required',
        });
        return;
      }
      payload = { url: data.url };
    }

    const form = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      form.set(key, value);
    });

    try {
      await fetcher<StagedAPIDefinitionType>(`${apiBaseUrl}/apis`, {
        body: form,
        method: 'POST',
        isFormData: true,
      });
      onImport?.();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      // Reset form after successful submission.
      reset();
    }
  });

  return (
    <form ref={formRef} className={bem('&', className)} onSubmit={onSubmit}>
      <RHFGroup control={control} name="file">
        {({ field }) => (
          <FileUploader
            accept="application/json,.yaml"
            fullWidth
            onUpload={async file => {
              field.onChange(file);
              return onSubmit();
            }}
          >
            Upload OpenAPI file
          </FileUploader>
        )}
      </RHFGroup>

      <hr className={bem('-divider')} />

      <RHFGroup control={control} isUrl name="url">
        {({ field }) => (
          <Flex align="center" className={bem('-import-url')} gap={0}>
            <Input placeholder="https://" {...field} />
            <Button loading={!!field.value && isSubmitting} type="submit">
              <Icon name="upload" />
              Import OpenAPI file
            </Button>
          </Flex>
        )}
      </RHFGroup>
    </form>
  );
}
