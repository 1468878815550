import React from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';

import ApiContentSkeleton from './ApiContentSkeleton';
import ApiReferenceCreatorForm, { ApiReferenceCreatorFormProvider, useApiReferenceCreatorForm } from './Form';
import styles from './index.module.scss';
import SidebarSkeleton from './SidebarSkeleton';

function Content() {
  const bem = useClassy(styles, 'ApiReferenceCreator');

  const { control } = useApiReferenceCreatorForm();
  const apiTitle = useWatch({ control, name: 'api.title' });
  const apiServerUrl = useWatch({ control, name: 'api.serverUrl' });

  return (
    <Flex align="stretch" className={bem()} gap={0} justify="start" tag="main">
      <SidebarSkeleton category={apiTitle} className={bem('-sidebar')} />
      <ApiContentSkeleton className={bem('-content')} serverUrl={apiServerUrl} />
      <Flex className={bem('-playground')} gap={0} layout="col">
        TBD: API playground skeletons
      </Flex>
    </Flex>
  );
}

/**
 * Workflow that helps customers with adding a new API definition to their docs
 * either by uploading their own specification file or entering one manually.
 * The main form is rendered in SuperHub's floating "island" panel while
 * skeleton placeholders are rendered the main content body.
 */
export default function ApiReferenceCreator() {
  return (
    <ApiReferenceCreatorFormProvider>
      <Content />
      <ApiReferenceCreatorForm />
    </ApiReferenceCreatorFormProvider>
  );
}
