import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import APIMethod from '@ui/API/Method';
import Flex from '@ui/Flex';
import Skeleton from '@ui/Skeleton';

import styles from './index.module.scss';

interface ApiContentSkeletonProps {
  className?: string;
  serverUrl?: string;
}

/**
 * Renders a skeleton of an API endpoint page on the Hub with exposed props to
 * substitute in custom values into specific sections.
 */
export default function ApiContentSkeleton({ className, serverUrl }: ApiContentSkeletonProps) {
  const bem = useClassy(styles, 'ApiContentSkeleton');
  const [showRequestHistory] = useProjectStore(s => [s.data.reference.request_history === 'enabled']);

  return (
    <div className={bem('&', className)}>
      <header className={bem('-headline')}>
        <h1 className={bem('-headline-title')}>
          <Skeleton width={160} />
        </h1>
        <Flex align="center" gap="sm" justify="start">
          <APIMethod fixedWidth type="get" />
          {serverUrl ? <span className={bem('-headline-url')}>{serverUrl}</span> : <Skeleton width={160} />}
        </Flex>
      </header>

      {!!showRequestHistory && (
        <section className={bem('-section')}>
          <header className={bem('-section-header')}>REQUEST HISTORY</header>
          <ul className={bem('-section-content')}>
            <li className={bem('-section-row')}>
              <Skeleton width={30} />
              <Skeleton width={30} />
              <Skeleton width={30} />
              <Skeleton width={60} />
            </li>
            <li className={bem('-section-row')}>
              <Skeleton width={90} />
              <Skeleton width={30} />
              <Skeleton className={bem('-status')} width={90} />
              <Skeleton width={90} />
            </li>
          </ul>
        </section>
      )}

      <section className={bem('-section')}>
        <header className={bem('-section-header')}>
          <Skeleton width={160} />
        </header>
        <ul className={bem('-section-content')}>
          <li className={bem('-section-row', '-section-row_header')}>
            <Skeleton width={40} />
          </li>
          <li>
            <ul className={bem('-section-nested-content')}>
              <li className={bem('-section-nested-row', '-section-nested-row_header')}>
                <Skeleton width={40} />
              </li>
              <li className={bem('-section-nested-row')}>
                <Skeleton width={100} />
                <Skeleton width={50} />
              </li>
              <li className={bem('-section-nested-row')}>
                <Skeleton width={70} />
                <Skeleton width={50} />
              </li>
              <li className={bem('-section-nested-row')}>
                <Skeleton width={70} />
                <Skeleton width={50} />
              </li>
            </ul>
          </li>
          <li>
            <ul className={bem('-section-nested-content')}>
              <li className={bem('-section-nested-row', '-section-nested-row_header')}>
                <Skeleton width={40} />
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section className={bem('-section')}>
        <header className={bem('-section-header')}>
          <Skeleton width="30%" />
        </header>
      </section>
    </div>
  );
}
