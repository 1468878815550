import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import type { SuperHubDocumentData } from '@core/store';

import useRecoveryData from '@routes/Dash/Project/DocsEditor/ContentEditor/useRecoveryData';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { useSuperHubEditorFormContext, useFormRecoveryContext, useFormConfirmationContext } from '../Context';

import { createRecoveryKey } from './util';

export default function useFormRecovery() {
  const { routeParentCategory, routeParentPage } = useParams<SuperHubRouteParams>();
  const { reset } = useSuperHubEditorFormContext();
  const { setIsRecovered } = useFormRecoveryContext();
  const confirm = useFormConfirmationContext();

  /**
   * Store and recover form data from local storage when the user has server
   * errors that prevent them from saving.
   * @todo move recovery feature out to separate component or hook.
   */
  const recoveryKey = createRecoveryKey(routeParentCategory, routeParentPage);
  const { recoveryData, clearRecoveryData, storeRecoveryData } = useRecoveryData<SuperHubDocumentData>(recoveryKey);

  const confirmRecovery = useCallback(async () => {
    const shouldRecover = await confirm({
      bodyText:
        "Open changes in your editor to continue working where you left off. The changes won't be applied until you save them.",
      cancelText: 'Discard',
      confirmText: 'Open',
      headingText: 'We found unsaved changes for this page.',
      cancelButtonProps: { kind: 'destructive', text: true },
      confirmButtonProps: { kind: 'primary' },
    });

    if (shouldRecover && recoveryData) {
      reset(recoveryData, {
        keepDefaultValues: true,
      });

      // Set the recovery flag to true to re-render the MarkdownEditor component.
      setIsRecovered?.(true);
    }

    clearRecoveryData();
  }, [clearRecoveryData, confirm, recoveryData, reset, setIsRecovered]);

  useEffect(() => {
    if (recoveryData) {
      confirmRecovery();
    }
  }, [confirmRecovery, recoveryData]);

  return {
    updateFormRecovery: storeRecoveryData,
  };
}
