import type { CustomPageReadType } from '@readme/api/src/mappings/custompage/types';

/**
 * Mock custom page document.
 */
export const mockCustomPageDocument: CustomPageReadType['data'] = {
  appearance: {
    fullscreen: false,
  },
  content: {
    body: 'Go tell it on the mountain',
    type: 'markdown',
  },
  links: {
    project: '/versions/1.0.0/projects/devilstore',
  },
  metadata: {
    description: 'custompage-mock-description',
    image: {
      uri: null,
      url: 'https://files.readme.io/qbtynrr3jn-owlbert.png',
    },
    keywords: 'pug, warm blanket, nap',
    title: 'test-metadata-title',
  },
  privacy: {
    view: 'public',
  },
  slug: 'test-slug',
  title: 'test-title',
  uri: '/versions/1.0.0/guides/test-slug',
  updated_at: new Date('9/19/2019').toISOString(),
};
