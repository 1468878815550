import React from 'react';

import useUniqueId from '@core/hooks/useUniqueId';

import Flex from '@ui/Flex';
import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext } from '../Context';
import Layout, { LayoutBody, LayoutHeader, LayoutTitle } from '../Layout';
import HeaderInput from '../shared/HeaderInput';
import MarkdownEditor from '../shared/MarkdownEditor';
import WhatsNextEditor from '../shared/WhatsNext';
import useFormSetFocus from '../useFormSetFocus';

function MockManualEditor() {
  return (
    <Flex
      justify="center"
      style={{
        border: '1px dashed var(--color-border-default)',
        borderRadius: 'var(--border-radius)',
        color: 'var(--color-text-default)',
        fontSize: '24px',
        marginBottom: 'var(--lg)',
        padding: 'var(--xl) var(--lg)',
      }}
    >
      🦉 ✨ Manual Editor by Marc ✨ 🦉
    </Flex>
  );
}

/**
 * Form fields specific to the `endpoint` page type.
 */
export default function EndpointPage() {
  const uid = useUniqueId('SuperHubEditorFormEndpointPage');
  const { control } = useSuperHubEditorFormContext();

  useFormSetFocus('title');

  return (
    <Layout>
      <LayoutHeader>
        <LayoutTitle>
          <RHFGroup control={control} id={uid('title')} name="title" required>
            {({ field }) => <HeaderInput {...field} />}
          </RHFGroup>
        </LayoutTitle>
        <RHFGroup control={control} id={uid('content-excerpt')} name="content.excerpt">
          {({ field }) => <HeaderInput {...field} value={field.value || ''} />}
        </RHFGroup>
      </LayoutHeader>
      <LayoutBody>
        <MockManualEditor />
        <MarkdownEditor />
        <WhatsNextEditor />
      </LayoutBody>
    </Layout>
  );
}
