import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';

/**
 * Mock guides page document.
 */
export const mockGuidesPageDocument: ReadGuideType['data'] = {
  allow_crawlers: 'disabled',
  category: {
    uri: '/versions/1.0.0/categories/guides/Documentation',
  },
  content: {
    body: 'Go tell it on the mountain',
    excerpt: 'test-excerpt',
    link: {
      url: null,
      new_tab: false,
    },
    next: {
      description: 'test-next-description',
      pages: [],
    },
  },
  href: {
    dash: 'https://dash.readme.com/project/mock-project/v1.0.0/docs/test-slug',
  },
  metadata: {
    description: 'test-metadata-description',
    image: {
      uri: null,
      url: 'https://files.readme.io/qbtynrr3jn-owlbert.png',
    },
    keywords: 'pug, warm blanket, nap',
    title: 'test-metadata-title',
  },
  parent: {
    uri: null,
  },
  privacy: {
    view: 'public',
  },
  project: {
    name: 'mock-project',
    subdomain: 'mock-project',
    uri: '/projects/me',
  },
  slug: 'test-slug',
  state: 'current',
  type: 'basic',
  title: 'test-title',
  uri: '/versions/1.0.0/guides/test-slug',
  updated_at: new Date('10/10/2010').toISOString(),
};
